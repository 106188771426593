import React from "react";
import styled from "styled-components";
import Card from "./Card";
import "./scroll.css";
import { Droppable } from "react-beautiful-dnd";

const TaskList = styled.div`
  transistion: background-color 0.2s ease;
  flex-grow: 1;
  min-height: 200px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export default function Column({ title, tasks, id, delCard, EditPlan }) {
  return (
    <div className="column res:min-w-[280px] res:bg-[#F1F2FD]">
      <div className="bg-[#F8F9FA] p-[16px] border res:bg-transparent">
        <div className="flex items-center gap-[10px] pb-[20px] uppercase">
          <div className="w-[30px] h-[30px] rounded-full text-white bg-[#379AE6] flex justify-center items-center">
            {id}
          </div>{" "}
          <h1 className="text-[20px] outfit">{title}</h1>
        </div>
        <Droppable droppableId={id}>
          {(provided, snapshot) => (
            <TaskList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {tasks.map((task, index) => (
                <Card
                  EditPlan={EditPlan}
                  delCard={delCard}
                  key={index}
                  index={index}
                  task={task}
                />
              ))}
              {provided.placeholder}
            </TaskList>
          )}
        </Droppable>
      </div>
    </div>
  );
}
