import React, { useEffect, useState } from "react";
import { getRequest } from "../utils/request";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

function TimeSpan({ _id, closeModal }) {
  const [data, setData] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    const path = pathname.includes("my-task")
      ? "/span-history/task/"
      : "/span-history/plan/";
    getRequest(path + _id, dispatch)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const status = ["TO DO", "PENDING", "BLOCKED", "DONE"];
  return (
    data && (
      <div className="fixed top-0 left-0 w-full h-screen bg-[#00000060] z-[99]">
        <div className=" h-screen flex justify-end">
          <div className="w-full p-[20px] max-w-[800px] bg-[white]  overflow-auto">
            <div className="relative mb-[20px]">
              <h1 className="text-[20px] LatoBold">ИСТОРИЯ ВРЕМЕНИ</h1>
              <button
                onClick={closeModal}
                className="absolute right-0 top-0 text-[32px] leading-[10px]"
              >
                &times;
              </button>
            </div>
            <div>
              <div>
                {data?.map((item, i) => (
                  <div
                    key={i}
                    className="border-t-[2px] border-b-[2px] p-[20px] gap-[20px] relative mb-[30px]"
                  >
                    <div className="grid grid-cols-2 gap-[10px] text-[#6E7787] w-[70%] min-w-[400px] text-[14px]">
                      <p>Название:</p>
                      <span className="text-black">{item?.name}</span>
                      <p>Введено:</p>
                      <span className="text-black">{item?.start_date}</span>
                      <p>Осталось:</p>
                      <span className="text-black">{item?.end_date}</span>
                      <p>Истекшее время:</p>
                      <span className="text-black">{item?.interval}</span>
                    </div>
                    <p className="text-[20px] outfit text-right">
                      {status[item?.status]}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default TimeSpan;
