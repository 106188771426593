import React from "react";
import { useDispatch } from "react-redux";
import { postRequest } from "../utils/request";
import { useParams } from "react-router-dom";
import MultipleFileInputComponent from "./FilePicker";

function AddManager({ closeModal, getData, isEdit = false, data }) {
  const dispatch = useDispatch();
  function formSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const path = isEdit ? "/admin/manager/update/" + data.id : "/admin/manager";
    postRequest(path, formData, dispatch)
      .then((res) => {
        console.log(res);
        getData();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function deleteManager() {
    postRequest("/admin/manager/delete/" + data.id, {}, dispatch)
      .then((res) => {
        console.log(res);
        getData();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="fixed h-screen w-full left-0 top-0 backdrop-blur-[1px] bg-[#00000055]  z-[99]">
      <div className="bg-[white] w-full max-w-[904px] p-[30px] absolute h-screen overflow-auto top-0 right-0 res:pb-[120px] res:pb-[100px]">
        <button
          onClick={closeModal}
          className="absolute right-[20px] text-[24px] top-[10px]"
        >
          &times;
        </button>
        <h1 className="text-[24px] mb-[20px]">
          {isEdit ? "EDIT" : "CREATE"} MANAGER{" "}
        </h1>
        <form onSubmit={formSubmit}>
          <div className="grid gap-[20px] uppercase">
            <div className="grid  gap-[10px] items-start">
              <p className="text-[16px] uppercase font-[700] outfit">Логин</p>
              <input
                className="bg-[#F3F4F6] rounded-[5px] p-[10px] outline-none "
                type="email"
                required
                placeholder="example@gmail.com"
                name="email"
                defaultValue={data?.email}
              />
            </div>
            <div className="grid  gap-[10px] items-start">
              <p className="text-[16px] uppercase font-[700] outfit">Пароль</p>
              <input
                className="bg-[#F3F4F6] rounded-[5px] p-[10px] outline-none "
                type="text"
                required
                placeholder="123456789"
                name="password"
                defaultValue={data?.password}
              />
            </div>
            <div className="flex justify-end pt-[20px] border-t gap-[30px] items-center res:justify-between">
              <button onClick={closeModal} type="button" className="res:text-[12px]">
                Отмена
              </button>
              {isEdit && (
                <button
                  className=" p-2 res:text-[14px] res:min-w-[100px] res:px-[10px] min-w-[170px]  rounded-[5px] text-white py-[10px] px-[20px] bg-[red] "
                  type="button"
                  onClick={deleteManager}
                >
                  Удалить
                </button>
              )}
              <button className=" p-2 res:text-[14px] res:min-w-[100px] res:px-[10px] min-w-[170px]  rounded-[5px] text-white py-[10px] px-[20px] bg-[#535CE8] ">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddManager;
