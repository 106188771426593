import React, { useState } from "react";
import { postRequest } from "../utils/request";
import { useDispatch } from "react-redux";

function NotifyItem({ data, getData }) {
  const [isShow, setisShow] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className="grid pb-[10px] border-b border-b-[#d5d5d5] cursor-pointer">
      <p
        className="text-[14px] font-semibold"
        onClick={() => {
          setisShow(!isShow);
          if (data.status === 0) {
            postRequest("/notify/read/" + data.id, {}, dispatch)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }}
      >
        {data.status === 0 && (
          <span className="  bg-[#9095A0] rounded-[4px] p-[4px] text-[12px]">
            NEW
          </span>
        )}{" "}
        {data?.title}
      </p>
      <p className="text-[12px]">{data?.description}</p>
      {isShow && (
        <div className="absolute top-0 left-0 w-full h-full overflow-auto bg-[white] p-[10px] rounded-[10px]">
          <h1
            onClick={() => {
              getData();
              setisShow(false);
            }}
            className="text-center uppercase font-semibold text-[18px] relative"
          >
            <div className="w-[14px] h-[14px] left-[8px] border-[black] absolute border-t-0 border-r-0 top-[3px]  border-[2px] rotate-45"></div>
            Notifications
          </h1>
          <h1 className="font-semibold">{data?.title}</h1>
          <p>{data?.description}</p>
        </div>
      )}
    </div>
  );
}

export default NotifyItem;
