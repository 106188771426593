import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import TimeSpan from "./TimeSpan";
import { useAuth } from "../redux/selectors";
import { ToTopArrow } from "./Svgs";

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: white;
  padding: 12px;
  box-shadow: 0px 2px 5px #171a1f2b;
  border-radius: 5px;
`;

export default function Card({ task, index, delCard, EditPlan }) {
  const [isEdit, setIsEdit] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const { user } = useAuth();
  const location = useLocation();
  const role = user?.roles?.[0]?.name;
  const isDisabled = {
    manager: false,
    user: true,
    superAdmin: false,
  };
  return (
    <>
      <Draggable
        draggableId={`${task.id}`}
        isDragDisabled={isDisabled[role]}
        key={task.id}
        index={index}
      >
        {(provided, snapshot) => (
          <div
            onClick={() => {
              if (isDisabled[role] && !location.pathname.includes("my-task")) {
                setIsEdit(true);
              }
            }}
          >
            <Container
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              isDragging={snapshot.isDragging}
              className="min-h-[210px] res:min-h-1 border"
            >
              <div className="flex h-full gap-2 flex-col justify-between flex-1">
                <div className="flex justify-between">
                  <p className="res:text-[14px] whitespace-nowrap text-ellipsis w-full overflow-hidden">
                    {task?.name?.slice(0, 28) || task?.description?.slice(0, 28)}
                    ...
                  </p>{" "}
                  <p className="text-[#DE3B40] flex items-center gap-[8px] res:text-[14px]">
                    <ToTopArrow /> Высокий
                  </p>
                </div>
                <div>
                  <p className="pb-[15px] res:text-[14px] res:py-[20px]">
                    Дата <span className="opacity-55">{task?.start_date}</span>
                  </p>
                  <div className="flex justify-between items-center">
                    <p className="text-[#9095A0] text-[24px]">#{task?.id}</p>
                    {!isDisabled[role] && (
                      <div className="flex gap-4">
                        <button onClick={() => setIsShow(true)}>
                          <img
                            src={require("../assets/images/reload.png")}
                            alt=""
                          />
                        </button>
                        <button onClick={() => delCard(task.id)}>
                          <img
                            src={require("../assets/images/trash.png")}
                            alt=""
                          />
                        </button>
                        <button onClick={() => setIsEdit(true)}>
                          <img
                            src={require("../assets/images/pen.png")}
                            alt=""
                          />
                        </button>
                      </div>
                    )}
                    {isDisabled[role] &&
                      location.pathname.includes("my-task") && (
                        <div className="flex gap-4">
                          <button onClick={() => setIsEdit(true)}>
                            <img
                              src={require("../assets/images/pen.png")}
                              alt=""
                            />
                          </button>
                          <button onClick={() => setIsShow(true)}>
                            <img
                              src={require("../assets/images/reload.png")}
                              alt=""
                            />
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              {isShow && (
                <TimeSpan _id={task.id} closeModal={() => setIsShow(false)} />
              )}
            </Container>
          </div>
        )}
      </Draggable>
      {isEdit && (
        <EditPlan
          defVal={task}
          isEdit={true}
          closeModal={() => setIsEdit(false)}
        />
      )}
    </>
  );
}
