import React from "react";
import { postRequest } from "../utils/request";
import { useDispatch } from "react-redux";
import { authChange, setUser } from "../redux/slices/auth";
import { useNavigate } from "react-router-dom";
function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function formSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    console.log(data);
    postRequest("/auth/login", data, dispatch)
      .then((res) => {
        if (res.success) {
          dispatch(authChange(true));
          dispatch(setUser(res));
          localStorage.setItem("token", res?.data?.token);
          localStorage.setItem("role", res?.data?.user?.roles[0].name)
          navigate("/");
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div
      onSubmit={formSubmit}
      className="w-full h-screen flex justify-center items-center"
    >
      <form className="w-full">
        <div className="grid gap-4 w-[50%] res:w-[90%] mx-auto max-w-[520px] border shadow-lg p-[30px]">
          <h1 className="text-[28px]">Авторизоваться</h1>
          <input
            type="email"
            placeholder="Электронная почта"
            required
            name="email"
            className="bg-slate-100 rounded border border-transparent focus:border-[#2658c2] p-[10px] outline-none w-full"
          />
          <input
            type="text"
            placeholder="Пароль"
            required
            name="password"
            className="bg-slate-100 rounded border border-transparent focus:border-[#2658c2] p-[10px] outline-none w-full"
          />
          <input
            type="submit"
            value={"Войти"}
            className="bg-[#2658c2] p-[10px] outline-none w-full cursor-pointer rounded text-white"
          />
        </div>
      </form>
    </div>
  );
}

export default Login;
