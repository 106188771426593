import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./Column";
import AddColumn from "./AddColumn";
import AddPlan from "./AddPlan";
import { Link } from "react-router-dom";
import { base_url, getRequest, postRequest } from "../utils/request";
import { useDispatch } from "react-redux";
import { useAuth } from "../redux/selectors";
import notif from "../assets/images/notif.svg";
import Wrapper from "./Wrapper";
import MultipleFileInputComponent from "./FilePicker";
import NotifyItem from "./NotifyItem";
import axios from "axios";
export default function Board() {
  const [projects, setProjects] = useState([]);
  const [notifies, setNotifies] = useState([]);
  const [openColumn, setOpenColumn] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  const [currentProject, setCurrentProject] = useState(0);
  const [columns, setColumns] = useState(false);
  const { user } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    getRequest("/manager/client-board/all", dispatch)
      .then((res) => {
        setProjects(res);
        setCurrentProject(res?.data[0]?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios
          .get(`${base_url}/notify/list`, {
            withCredentials: false,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            setNotifies(res?.data?.data);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        return error;
      }
    };

    // Initial fetch when component mounts
    fetchData();

    // Set up interval to repeat fetchData every 5 seconds
    const intervalId = setInterval(fetchData, 65000);

    // Clean up interval to prevent memory leaks
    return () => clearInterval(intervalId);
  }, []);
  function fetchingData() {
    getRequest("/manager/column/cards/" + currentProject, dispatch)
      .then((res) => {
        setColumns(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getRequest("/notify/list", dispatch)
      .then((res) => {
        setNotifies(res?.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    if (currentProject) {
      fetchingData();
    }
  }, [currentProject]);
  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination || source.droppableId === destination.droppableId) return;

    console.log(draggableId, destination.droppableId);
    postRequest(
      "/change/plan",
      {
        plan_id: draggableId,
        column_id: destination.droppableId,
      },
      dispatch
    )
      .then((res) => {
        console.log(res);
        fetchingData();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function delCard(_id) {
    postRequest("/manager/plan/delete/" + _id, {}, dispatch)
      .then((res) => {
        console.log(res);
        fetchingData();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function EditPlan({ closeModal, defVal }) {
    const dispatch = useDispatch();
    function formSubmit(e) {
      e.preventDefault();
      const form = e.target;
      const formData = new FormData(form);

      postRequest("/manager/plan/update/" + defVal.id, formData, dispatch)
        .then((res) => {
          console.log(res);
          fetchingData();
          closeModal();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return (
      <div className="fixed h-screen w-full left-0 top-0 backdrop-blur-[1px] bg-[#00000055]  z-[99]">
        <div className="bg-[white] w-full max-w-[904px] p-[30px] absolute h-screen overflow-auto top-0 right-0 res:pb-[120px] res:pb-[100px]">
          <button
            onClick={closeModal}
            className="absolute right-[20px] text-[24px] top-[10px]"
          >
            &times;
          </button>
          <h1 className="text-[32px] outfit text-left font-[700] mb-[20px]">
            План
          </h1>
          <form onSubmit={formSubmit}>
            <div className="grid gap-[20px]">
              <div className="w-[40%] min-w-[270px]">
                <div className="grid grid-cols-2 gap-[10px]">
                  <div className="flex items-center gap-[8px]">
                    <img
                      src={require("../assets/images/calendar.png")}
                      alt=""
                    />
                    <p className="text-[14px] opacity-55">Начало</p>
                  </div>
                  <input
                    type="date"
                    defaultValue={defVal?.start_date}
                    name="start_date"
                    className="bg-[#F3F4F6] px-[10px] py-[5px] rounded-full p-[10px] outline-none "
                  />
                  <div className="flex items-center gap-[8px]">
                    <img
                      src={require("../assets/images/calendar.png")}
                      alt=""
                    />
                    <p className="text-[14px] opacity-55">Конец</p>
                  </div>
                  <input
                    type="date"
                    name="end_date"
                    defaultValue={defVal?.end_date}
                    className="bg-[#F3F4F6] px-[10px] py-[5px] rounded-full p-[10px] outline-none "
                  />

                  <div className="flex items-center gap-[8px]">
                    <img
                      src={require("../assets/images/calendar.png")}
                      alt=""
                    />
                    <p className="text-[14px] opacity-55">Status</p>
                  </div>
                  <select
                    className="bg-[#F3F4F6] px-[10px] py-[5px] rounded-full p-[10px] outline-none "
                    name="status"
                    defaultValue={defVal?.status}
                  >
                    <option value="0">TO DO</option>
                    <option value="1">PENDING</option>
                    <option value="2">BLOCKED</option>
                    <option value="3">DONE</option>
                  </select>

                  <div className="flex items-center gap-[8px]">
                    <img
                      src={require("../assets/images/calendar.png")}
                      alt=""
                    />
                    <p className="text-[14px] opacity-55">Столбец</p>
                  </div>
                  <select
                    className="bg-[#F3F4F6] px-[10px] py-[5px] rounded-full p-[10px] outline-none "
                    name="column_id"
                    defaultValue={defVal?.column_id}
                  >
                    <option></option>
                    {columns?.map((item, i) => (
                      <option value={item?.id}>{item?.title}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="grid  gap-[10px] items-start pb-[20px] border-b ">
                <p className="text-[16px] uppercase font-[700] outfit">
                  Описание
                </p>
                <textarea
                  name="description"
                  className="bg-[#F3F4F6] rounded-[5px] p-[10px] outline-none "
                  id=""
                  defaultValue={defVal?.description}
                  rows="1"
                ></textarea>
              </div>
              <div className="grid  gap-[10px] items-start pb-[20px] border-b ">
                <p className="text-[16px] uppercase font-[700] outfit">
                  Документы
                </p>
                <MultipleFileInputComponent
                  name="attachments[]"
                  defVal={defVal}
                />
              </div>
              <div className="grid  gap-[10px] items-start pb-[20px]">
                <textarea
                  name="comment"
                  defaultValue={defVal?.comment}
                  className="bg-[#F3F4F6] rounded-[5px] p-[10px] outline-none "
                  id=""
                  placeholder="Введите комментарий"
                  rows="1"
                ></textarea>
              </div>

              <div className="flex justify-end pt-[20px] border-t gap-[30px] items-center">
                <button onClick={closeModal} type="button">
                  Отмена
                </button>
                <button className=" p-2 min-w-[170px]  rounded-[5px] text-white py-[10px] px-[20px] bg-[#535CE8] ">
                  Сохранить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return (
    columns && (
      <Wrapper>
        <div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <nav className="border-b">
              <div className="px-[36px] res:px-[20px] max-w-[1920px] mx-auto my-[20px]">
                <div className="flex justify-between items-center res:items-start">
                  <div className="m-0">
                    <h1 className="text-[20px] font-[700] mb-[5px] hidden res:block">
                      Мои задания
                    </h1>
                    <select
                      onChange={(e) => {
                        setCurrentProject(e.target.value);
                      }}
                      className=" p-2 min-w-[170px] res:min-w-[140px] res:p-[5px_10px] rounded-[5px] text-white bg-[#535CE8] outline-none"
                    >
                      {projects?.data?.map((project) => (
                        <option key={project?.id} value={project?.id}>
                          {project?.owner_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex gap-3 items-center res:mt-[5px]">
                    <img
                      src={"https://joesch.moe/api/v1/random?key=9"}
                      className="w-[40px] h-[40px] rounded-full bg-[#9095A0] res:hidden"
                      alt=""
                    />
                    <div className="grid res:hidden">
                      <p className="text-[16px]">{user?.owner_name}</p>
                      <p className="text-[10px]">{user?.email}</p>
                    </div>
                    <div className="relative">
                      <button
                        onClick={() => {
                          setOpenNotify(!openNotify);
                        }}
                      >
                        <img src={notif} alt="notif" />
                      </button>
                      {openNotify && (
                        <div className="absolute w-[350px] res:w-[90vw] res:fixed res:right-[0vw] z-[90] p-[20px] border-[#eaeaea] border right-0 rounded-[10px] bg-[white]">
                          <div className="relative w-full">
                            <h1 className="text-[20px] font-bold text-left">
                              Notifications
                            </h1>
                            <button
                              onClick={() => {
                                setOpenNotify(false);
                              }}
                              className="absolute right-0 top-0 text-[35px] leading-[1px]"
                            >
                              &times;
                            </button>
                          </div>
                          <div className="grid  gap-[10px] max-h-[500px] overflow-auto">
                            {notifies?.map((item) => (
                              <NotifyItem data={item} getData={fetchingData} />
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <Link to={"/login"}>
                      <button>
                        <img
                          src={require("../assets/images/logout.png")}
                          className="md:ml-[30px]"
                          alt=""
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
            <div className="px-[36px] res:px-[20px] max-w-[1920px] mx-auto my-[20px]">
              <div className="flex flex-row justify-between items-center mb-[20px]">
                <h1 className="text-[32px] text-left outfit font-[700] res:hidden">
                  Lorem ipsum
                </h1>
                <Link to={"/my-task/" + currentProject}>
                  <button className="py-[7px] res:py-[5px] text-[#9095A0] text-[14px] border px-[45px] res:px-[0] res:min-w-[140px] bg-[#F8F9FA] rounded-[5px]">
                    Задание
                  </button>
                </Link>
              </div>
              <div className="flex gap-4  justify-between text-[12px]">
                <div className="flex gap-4">
                  <button
                    onClick={() => {
                      setOpenColumn(true);
                    }}
                    className=" p-2 min-w-[170px] res:min-w-[140px]  rounded-[5px] text-white bg-[#535CE8] mb-[20px] outline-none"
                  >
                    + Добавить столбец
                  </button>
                  {openColumn && (
                    <AddColumn
                      getData={fetchingData}
                      projectId={currentProject}
                      allCols={columns}
                      closeModal={() => setOpenColumn(false)}
                    />
                  )}
                  <button
                    onClick={() => {
                      setOpenPlan(true);
                    }}
                    className=" p-2 min-w-[170px] res:min-w-[140px]  rounded-[5px] text-white bg-[#535CE8] mb-[20px] outline-none"
                  >
                    + Добавить план
                  </button>
                  {openPlan && (
                    <AddPlan
                      getData={fetchingData}
                      allCols={columns}
                      closeModal={() => setOpenPlan(false)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="px-[36px] res:px-[20px] max-w-[1920px] mx-auto  overflow-x-auto max-h-[75vh] res:max-h-max res:p-[20px]">
              <div className="flex justify-start items-start mx-auto gap-[24px]">
                {columns?.map((column) => (
                  <Column
                    title={column?.title}
                    tasks={column?.plans}
                    EditPlan={EditPlan}
                    id={column?.id}
                    delCard={delCard}
                  />
                ))}
              </div>
            </div>
          </DragDropContext>
        </div>
      </Wrapper>
    )
  );
}
