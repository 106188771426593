import React, { useEffect, useState } from "react";
import AddClientModal from "./AddClientModal";
import Wrapper from "./Wrapper";
import { getRequest } from "../utils/request";
import { useDispatch } from "react-redux";
import { useAuth } from "../redux/selectors";
import { Link } from "react-router-dom";
import proSVG from "../assets/images/pro.svg";
import AddManager from "./AddManager";

function CreateAll() {
  const [page, setPage] = useState(1);
  function getData() {
    getRequest("/admin/manager/all", dispatch)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const [addModal, setAddModal] = useState(false);
  const [data, setData] = useState([]);
  function RowTab({ items }) {
    const [isModal, setIsModal] = useState(false);
    const [show, setShow] = useState(false);
    return (
      <>
        <div className="grid res:hidden grid-cols-5 text-[14px] p-[23px] items-center border-b text-left">
          <p>{items?.id}</p>
          <p>{items?.created_at.slice(0, 10)}</p>
          <p>{items?.email}</p>

          <p>{items?.password || "****************"}</p>
          <div className="flex justify-end">
            <button
              onClick={() => setIsModal(true)}
              className="p-[4px_8px] rounded-full bg-[#F1F8FD] text-[#379AE6]"
            >
              Редактировать
            </button>
          </div>
        </div>
        {isModal && (
          <AddManager
            closeModal={() => setIsModal(false)}
            isEdit={true}
            id={items?.id}
            data={items}
            getData={getData}
          />
        )}
        <div className="py-[20px] border-b hidden res:block">
          <div
            onClick={() => setShow(!show)}
            className="flex justify-between cursor-pointer items-center"
          >
            <p className="text-[16px] font-[700]">ID</p>
            <p className="text-[16px] font-[700]">{items?.id}</p>
            <button
              style={{
                transform: !show ? "rotate(180deg)" : "",
              }}
            >
              <img src={require("../assets/images/arrow.png")} alt="" />
            </button>
          </div>
          {show && (
            <div className="grid grid-cols-2 w-[92%]">
              <div className="grid">
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  DATE
                </p>

                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  Логин
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  Пароль
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  Действия
                </p>
              </div>
              <div className="grid">
                <p className="py-[10px] text-[14px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {items?.created_at.slice(0, 10)}
                </p>

                <p className="py-[10px] text-[14px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {items?.email}
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {items?.password || "*********"}
                </p>
                <p className="py-[4px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  <button
                    className="bg-[#F1F8FD] rounded-full p-[10px] text-[#379AE6] py-[5px]"
                    onClick={() => setIsModal(true)}
                  >
                    Редактировать
                  </button>
                </p>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
  const { user } = useAuth();

  const dispatch = useDispatch();
  useEffect(() => {
    getData();
  }, [page]);

  return (
    <Wrapper>
      <nav className="border-b border-[#171A1F26]">
        <div className="max-w-[1920px] flex items-center justify-between mx-auto my-[20px] px-[36px] res:px-[20px]">
          <Link>
            <button className="  flex gap-3 items-center">
              <img src={proSVG} alt="" />
              <p className="opacity-70">Доска прогресса</p>
            </button>
          </Link>
          <div className="flex justify-end items-center">
            <div className="flex gap-3 items-center">
              <img
                src={"https://joesch.moe/api/v1/random?key=9"}
                className="w-[40px] h-[40px] rounded-full bg-[#9095A0] res:hidden"
                alt=""
              />
              <div className="grid res:hidden">
                <p className="text-[16px]">{user?.owner_name}</p>
                <p className="text-[10px]">{user?.email}</p>
              </div>

              <Link to={"/login"}>
                <button>
                  <img
                    src={require("../assets/images/logout.png")}
                    className="ml-[30px]"
                    alt=""
                  />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div className="px-[36px] res:px-[20px] max-w-[1920px] py-[20px] mx-auto overflow-auto h-[85vh]">
        <div className="py-[50px] pt-[30px] res:pt-[10px]">
          <div className="flex justify-end res:justify-start">
            <button
              class=" p-2 min-w-[170px] res:text-[12px]  rounded-[5px] text-white mb-[30px] px-[20px] bg-[#535CE8]"
              onClick={() => setAddModal(true)}
            >
              + Добавить пользователя
            </button>
            {addModal && (
              <AddManager
                getData={getData}
                closeModal={() => setAddModal(false)}
              />
            )}
          </div>
          <div className="w-full mx-auto border res:border-none">
            <div className="grid res:hidden grid-cols-5 uppercase text-[13px] text-left p-[23px] border-b bg-[#FAFAFB]">
              <p>ID</p>
              <p>DATE</p>
              <p>Логин</p>
              <p>Пароль</p>
              <p className="text-right">Действия</p>
            </div>
            {data?.data?.map((item, index) => (
              <RowTab key={index} items={item} />
            ))}
          </div>
          <div className="hidden justify-center gap-2 my-2">
            <button
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}
              className="border border-[#9095A0] p-[10px] text-[#9095A0]"
            >
              Perv
            </button>
            <button className="border border-[#9095A0] p-[10px] text-[#9095A0]">
              {data?.data?.last_page}/{data?.data?.current_page}
            </button>
            <button
              onClick={() => {
                if (data?.data?.last_page !== data?.data?.current_page) {
                  setPage(page + 1);
                }
              }}
              className="border border-[#9095A0] p-[10px] text-[#9095A0]"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default CreateAll;
