import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./Column";
import { Link } from "react-router-dom";
import { base_url, getRequest, postRequest } from "../utils/request";
import { useDispatch } from "react-redux";
import { useAuth } from "../redux/selectors";
import notif from "../assets/images/notif.svg";
import AddTask from "./AddTask";
import Wrapper from "./Wrapper";
import MultipleFileInputComponent from "./FilePicker";
import NotifyItem from "./NotifyItem";
import axios from "axios";
import AddTaskUser from "./AddTaskUser";
export default function UserTask() {
  const { user } = useAuth();
  const [notifies, setNotifies] = useState([]);
  const [openPlan, setOpenPlan] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  const [columns, setColumns] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios
          .get(`${base_url}/notify/list`, {
            withCredentials: false,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            setNotifies(res?.data?.data);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        return error;
      }
    };

    // Initial fetch when component mounts
    fetchData();

    // Set up interval to repeat fetchData every 5 seconds
    const intervalId = setInterval(fetchData, 65000);

    // Clean up interval to prevent memory leaks
    return () => clearInterval(intervalId);
  }, []);

  function fetchingData() {
    getRequest("/user/task/" + user?.id, dispatch)
      .then((res) => {
        setColumns(res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getRequest("/notify/list", dispatch)
      .then((res) => {
        setNotifies(res?.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchingData();
  }, []);

  function delCard(_id) {
    console.log(_id);
  }

  function EditPlan({ closeModal, defVal }) {
    const dispatch = useDispatch();
    function formSubmit(e) {
      e.preventDefault();
      const form = e.target;
      const formData = new FormData(form);

      postRequest("/user/task/update/" + defVal.id, formData, dispatch)
        .then((res) => {
          console.log(res);
          fetchingData();
          closeModal();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return (
      <div className="fixed h-screen w-full left-0 top-0 backdrop-blur-[1px] bg-[#00000055]  z-[99]">
        <div className="bg-[white] w-full max-w-[904px] p-[30px] absolute h-screen overflow-auto top-0 right-0 res:pb-[120px] res:pb-[100px]">
          <button
            onClick={closeModal}
            className="absolute right-[20px] text-[24px] top-[10px]"
          >
            &times;
          </button>
          <h1 className="text-[32px] LatoBold text-left font-[700] mb-[20px]">
            Добавить задание
          </h1>
          <form onSubmit={formSubmit}>
            <div className="grid gap-[20px] uppercase">
              <div className="w-[40%] min-w-[270px]">
                <div className="grid grid-cols-2 gap-[10px]">
                  <div className="flex items-center gap-[8px]">
                    <img
                      src={require("../assets/images/calendar.png")}
                      alt=""
                    />
                    <p className="text-[14px] opacity-55">Начало</p>
                  </div>
                  <input
                    type="date"
                    name="start_date"
                    defaultValue={defVal.start_date}
                    className="bg-[#F3F4F6] px-[10px] py-[5px] rounded-full p-[10px] outline-none "
                  />
                  <div className="flex items-center gap-[8px]">
                    <img
                      src={require("../assets/images/calendar.png")}
                      alt=""
                    />
                    <p className="text-[14px] opacity-55">Конец</p>
                  </div>
                  <input
                    type="date"
                    defaultValue={defVal.end_date}
                    name="end_date"
                    className="bg-[#F3F4F6] px-[10px] py-[5px] rounded-full p-[10px] outline-none "
                  />

                  <div className="flex items-center gap-[8px]">
                    <img src={require("../assets/images/status.png")} alt="" />
                    <p className="text-[14px] opacity-55">Status</p>
                  </div>
                  <select
                    className="bg-[#F3F4F6] px-[10px] py-[5px] rounded-full p-[10px] outline-none "
                    name="status"
                    defaultValue={defVal.status}
                  >
                    <option value="0">TO DO</option>
                    <option value="1">PENDING</option>
                    <option value="2">BLOCKED</option>
                    <option value="3">DONE</option>
                  </select>
                </div>
              </div>

              <div className="grid  gap-[10px] items-start">
                <p className="text-[16px] uppercase font-[700] outfit">
                  Название
                </p>
                <textarea
                  name="name"
                  className="bg-[#F3F4F6] rounded-[5px] p-[10px] outline-none "
                  id=""
                  placeholder="Введите название"
                  defaultValue={defVal.name}
                  rows="1"
                ></textarea>
              </div>

              <div className="grid  gap-[10px] items-start">
                <p className="text-[16px] uppercase font-[700] outfit">
                  Oтветственный
                </p>
                <textarea
                  name="responsible"
                  className="bg-[#F3F4F6] rounded-[5px] p-[10px] outline-none "
                  defaultValue={defVal.responsible}
                  rows="1"
                ></textarea>
              </div>
              <div className="grid  gap-[10px] items-start pb-[20px] border-b">
                <p className="text-[16px] uppercase font-[700] outfit">
                  Описание
                </p>
                <textarea
                  name="description"
                  className="bg-[#F3F4F6] rounded-[5px] p-[10px] outline-none "
                  defaultValue={defVal.description}
                  rows="1"
                ></textarea>
              </div>
              <div className="grid  gap-[10px] items-start ">
                <p className="text-[16px] uppercase font-[700] outfit">
                  Документы
                </p>
                <MultipleFileInputComponent
                  name="attachments[]"
                  defVal={defVal}
                />
              </div>
              <div className="flex justify-end pt-[20px] border-t gap-[30px] items-center">
                <button onClick={closeModal} type="button">
                  Отмена
                </button>
                <button className=" p-2 min-w-[170px]  rounded-[5px] text-white py-[10px] px-[20px] bg-[#535CE8] ">
                  Сохранить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    columns && (
      <Wrapper>
        <div>
          <DragDropContext>
            <div className="px-[36px] res:px-[20px] pb-[20px] border-b max-w-[1920px] mx-auto my-[20px]">
              <div>
                <div className="flex justify-end items-center res:justify-between">
                  <h1 className="hidden res:block font-[700] text-[20px]">
                    Мои задания
                  </h1>
                  <div className="flex gap-3 items-center res:pt-[5px]">
                    <img
                      src={"https://joesch.moe/api/v1/random?key=9"}
                      className="w-[40px] h-[40px] rounded-full bg-[#9095A0] res:hidden"
                      alt=""
                    />
                    <div className="grid res:hidden">
                      <p className="text-[16px]">{user?.owner_name}</p>
                      <p className="text-[10px]">{user?.email}</p>
                    </div>
                    <div className="relative">
                      <button
                        onClick={() => {
                          setOpenNotify(!openNotify);
                        }}
                      >
                        <img src={notif} alt="notif" />
                      </button>
                      {openNotify && (
                        <div className="absolute w-[350px] res:w-[90vw] res:fixed res:right-[0vw] z-[90] p-[20px] border-[#eaeaea] border right-0 rounded-[10px] bg-[white]">
                          <div className="relative w-full">
                            <h1 className="text-[20px] font-bold text-left">
                              Notifications
                            </h1>
                            <button
                              onClick={() => {
                                setOpenNotify(false);
                              }}
                              className="absolute right-0 top-0 text-[35px] leading-[1px]"
                            >
                              &times;
                            </button>
                          </div>
                          <div className="grid  gap-[10px] max-h-[500px] overflow-auto">
                            {notifies?.map((item) => (
                              <NotifyItem data={item} getData={fetchingData} />
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <Link to={"/login"}>
                      <button>
                        <img
                          src={require("../assets/images/logout.png")}
                          className="ml-[30px] res:ml-0"
                          alt=""
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-[36px] res:px-[20px] max-w-[1920px] mx-auto my-[20px]">
              <div class="flex justify-between items-center">
                <p class="text-[32px] text-left outfit font-[700] res:hidden">
                  Мои задания
                </p>
                <div class="flex justify-between items-center gap-[10px]">
                  <Link to={"/"}>
                    <button class=" p-2 res:text-[12px] res:min-w-[140px] min-w-[170px]  rounded-[5px] bg-[#F8F9FA] border px-[20px] text-[#9095A0]">
                      Доска прогресса
                    </button>
                  </Link>
                  <button
                    onClick={() => setOpenPlan(true)}
                    class=" p-2 res:text-[12px] res:min-w-[140px] min-w-[170px]  rounded-[5px] text-white px-[20px] bg-[#535CE8]"
                  >
                    + Добавить задание
                  </button>
                  {openPlan && (
                    <AddTaskUser
                      getData={fetchingData}
                      closeModal={() => setOpenPlan(false)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="px-[36px] res:pt-[0] res:px-[20px] max-w-[1920px] mx-auto  overflow-x-auto max-h-[75vh] res:max-h-max res:p-[20px]">
              <div className="flex justify-start items-start mx-auto gap-[24px]">
                <Column
                  title={"TO DO"}
                  tasks={columns?.data?.filter((item) => item?.status === "0")}
                  EditPlan={EditPlan}
                  id={0}
                  delCard={delCard}
                />
                <Column
                  title={"PENDING"}
                  tasks={columns?.data?.filter((item) => item?.status === "1")}
                  EditPlan={EditPlan}
                  id={1}
                  delCard={delCard}
                />
                <Column
                  title={"BLOCKED"}
                  tasks={columns?.data?.filter((item) => item?.status === "2")}
                  EditPlan={EditPlan}
                  id={2}
                  delCard={delCard}
                />
                <Column
                  title={"DONE"}
                  tasks={columns?.data?.filter((item) => item?.status === "3")}
                  EditPlan={EditPlan}
                  id={3}
                  delCard={delCard}
                />
              </div>
            </div>
          </DragDropContext>
        </div>
      </Wrapper>
    )
  );
}
